.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0 auto;
}

.box {
    max-width: 440px;
    width: 100%;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    padding: 40px 24px;
    margin: 24px 10px;
    background: #FFFFFF;
    box-shadow: 0px 8px 40px rgba(67, 97, 238, 0.16);
    border-radius: 8px;

    text-align: center;
}

.text {
    margin-top: 12px;
    color: #6D6F77;
}

.title {
    color: #2F313C;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.15px;
}