@import "../../../themes/breakpoints.scss";

.pageWrapper {
  padding: 1.25rem 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  @include less-1000 {
    padding: 1rem 8px;
  }
}
