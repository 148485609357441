@import "../../themes/breakpoints";

.header {
  width:100%;
  box-shadow: 0px 2px 16px rgba(67, 97, 238, 0.08);
  display: flex;
  justify-content: space-between;
  padding: 16px 72px;

  @include less-1000 {
    flex-direction: column;
    padding: 16px;
  }
}

.search {
  width:390px;
}

.searchWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include less-1000 {
    margin-top: 20px;
  }
}

.logoWrapper {
  display: flex;
  align-items: center;
}

.invisible1000 {
  @include less-1000 {
    display: none;
  }
}

.exitBtn {
  border: none !important;
  padding: 14px 24px !important;
  border-radius: 8px !important;

  @include less-1000 {
    padding: 10px 16px !important;
    min-width: 52px;
    height: 40px;

    & > span {
      margin-left: 0;
      margin-right: 0;
    }
  }
}