$content-tablet-width: 1000px;
$iphone-x-width: 375px;
$iphone-se-width: 320px;
$iphone-12-width: 390px;


@mixin for-phone {
  @media screen and (max-width: $iphone-12-width) {
    @content;
  }
}

@mixin less-1000 {
  @media screen and (max-width: $content-tablet-width) {
    @content;
  }
}

@mixin more-1000 {
  @media screen and (min-width: $content-tablet-width) {
    @content;
  }
}

@mixin less-1400 {
  @media screen and (max-width: 1400px) {
    @content;
  }
}