@import "../../themes/breakpoints.scss";

.secondary {
    color: #6D6F77;
}

.notFoundWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    h3{
        margin-bottom: 8px;
    }
    p {
        margin: 0;
        color: #6D6F77;
    }

    @include less-1000{
        padding-inline: 8px;
    }
}

.notFoundIcon {
    width: 6rem;
    height: 6rem;
    padding: 24px;
    margin-bottom: 24px;
    align-content: center;
    background-color: rgba(247, 37, 133, 0.16);
    border-radius: 6rem;
}

.patientsFound {
    margin-left: 1rem;
}

.patientsTotal {
    @include more-1000 {
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        /* 150% */
        letter-spacing: 0.15px;
    }

    @include less-1000 {
        margin-top: 8px;
        margin-bottom: 16px;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        /* 150% */
        letter-spacing: 0.15px;
    }
}

.patientSearch {
    display: flex;
    flex-direction: row;
    gap: 24px;

    @include less-1000 {
        flex-direction: column;
        gap: 0;
    }

    h1 {
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        /* 150% */
        letter-spacing: 0.15px;
    }

    p {
        margin: 0;
        color: #6D6F77;
        /* Body 1 */
        font-family: Inter;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        /* 141.176% */
        letter-spacing: -0.5px;
    }
}

.patientsTableWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 60px;
}