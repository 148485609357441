@import "../../../themes/breakpoints";

.table {
  font-family: Roboto;
  margin-bottom: 0;
  border-spacing: 0;
  padding-inline: 8px !important;
  thead {
    tr {
      th{
        padding: 0;
        padding-top: 16px;
        padding-bottom: 16px;
        &:first-child {
          padding-left: 24px;
        }
        &:last-child {
          padding-right: 24px;
        }
      }
    }
  }
}

.MuiTable-root {
  &.table {
    @include less-1000 {
      border-spacing: 0 4px !important;
    }
  }
}

.MuiCollapse-entered {
  padding: 12px;
}

.collapseTableWrapper {
  background: #FFFFFF;
  padding: 12px;
  padding-top: 0;
  border: 2px solid transparent;
  border-top: 0;
  box-shadow: -1px 14px 16px 0px rgba(67, 97, 238, 0.08);
  border-radius: 0 0 8px 8px;

  .collapseTable {
    .tableCollapseRow {
      td {
        padding: 12px 0;

        @include less-1000 {
          padding: 0;
        }

        &:first-child {
          @include more-1000 {
            padding-left: 12px;
            width: 31.5%;
          }

          @include less-1000 {
            margin-top: 12px;
          }
        }

        &:last-child {
          @include more-1000 {
            padding-right: 12px;
          }

          @include less-1000 {
            margin-top: 16px;
          }
        }
      }

      @include less-1000 {
        padding: 8px 0 8px 0;
      }
    }

    &>.tableCollapseRow:first-child {
      td {
        padding-bottom: 12px;
      }
      @include less-1000 {
        font-family: Inter;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        /* 141.176% */
        letter-spacing: -0.5px;
      }
    }
  }

  hr {
    margin: 0 20px 12px;
    height: 1px;
    border: 1px;
    color: rgba(67, 97, 238, 0.16);
    border-color: rgba(67, 97, 238, 0.16);
    background-color: rgba(67, 97, 238, 0.16);

    @include less-1000 {
      margin-bottom: 16px;
    }
  }

  @include less-1000 {
    padding: 0 16px 16px 16px;
  }

  &.hover {
    @include more-1000 {
      border-color: rgba(67, 97, 238, 0.16);
    }
  }
}

.collapseTable {
  margin-bottom: 0;
  padding-inline: 12px;
}

.tableRow {
  background: #FFFFFF;
  box-shadow: 0px 2px 16px 0px rgba(67, 97, 238, 0.08);

  td {
    padding: 0;
    padding-top: 28px;
    padding-bottom: 28px;
    border: 2px solid transparent;
    @include less-1000 {
      padding: 0 16px;
      font-size: 13px;
    }
    &:first-child {
      border-right: 0;
      padding-left: 24px;
      @include less-1000 {
        padding-top: 16px;
        padding-left: 16px;
      }
    }
    &:last-child {
      border-left: 0;
      width: 5%;
      white-space: nowrap;
      padding-right: 24px;
    }
    &:not(:first-child):not(:last-child) {
      border-inline: 0;
      border-radius: 0;
    }
    &.hover {
      @include more-1000 {
        border-color: rgba(67, 97, 238, 0.16);
      }
    }
  }

  @include less-1000 {
    display: block;
    position: relative;
  }
}

.tableCollapseRow {
  border: none;
  border-radius: 8px;
  background: #FFFFFF;
  padding: 12px;

  @include more-1000 {
    &:hover:not(:first-child) {
      background: rgba(67, 97, 238, 0.08);
      td {
        color: #4361EE;
        &:first-child {
          border-radius: 8px 0 0 8px;
        }
        &:last-child {
          border-radius: 0 8px 8px 0;
        }
      }
    }
  }


  td {
    @include less-1000 {
      padding: 0 16px;

      &:first-child {
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }
      &:not(:first-child) {
        font-family: Roboto;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 138.462% */
      }
    }
  }

  @include less-1000 {
    display: block !important;
  }
}

.headerCollapse {
  padding: 12px 8px;
  span {
      font-weight: 600;
      font-size: 17px;
      line-height: 24px;
      letter-spacing: -0.5px;
    }
}

.tableCell {
  border: none transparent;
  border-radius: 3px;
}

.mobileCollapse {
  position: absolute;
  top: 40%;
  right: 20px;
}
