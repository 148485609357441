.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0 auto;
}

.loginBox {
  max-width:440px;
  width:100%;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  padding: 40px 24px;
  margin: 24px 10px;
  background: #FFFFFF;
  box-shadow: 0px 8px 40px rgba(67, 97, 238, 0.16);
  border-radius: 8px;

  text-align: center;
}

.email {
  margin: 24px 0 0 0 !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border: solid 1px #ccc;
  border-radius: 8px;
  &.failed {
    border-color: rgba(247, 37, 133, 1) !important;
  }
}

.loginBtn {
  background: #4361EE;
  border-radius: 8px;
  color: #FFFFFF;
  padding: 14px 0;

  &:hover {
    background: #4361EE;
    color: #FFFFFF;
  }
}

.text {
  margin-top: 12px;
  color: #6D6F77;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.title {
  color: #2F313C;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.15px;
}

.successIcon{
    display: flex;
    width: 6rem;
    height: 6rem;
    padding: 24px;
    margin-bottom: 24px;
    align-self: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: rgba(51, 182, 93, 0.16);
    border-radius: 6rem;
}

.anotherEmail {
  margin-top: 24px;
  cursor: pointer;
  color: #4361EE;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 133.333% */
  letter-spacing: -0.5px;
}

.authCodeContainer {
  
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin: 24px 0 0 0 !important;

  p.authInput {
    justify-self: start;
  }
}

.authInput {
  width: 15%;
  padding: 12px 12px 12px 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border: solid 1px #ccc;
  border-radius: 8px;
  text-align: center;

  &:focus {
    outline: 0 !important;
    border-color: #8B8C92
  }
  &:hover {
    border-color: #8B8C92
  }
}

.authInput.failed { 
  border-color: rgba(247, 37, 133, 1);
}

p.authError {
  margin: 0;
  align-self: start;
  margin-top: 12px;
  margin-left: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(247, 37, 133, 1);
}