.chartWrapper {
  border-radius: 8px;
  border: 1px solid var(--grey-grey-200, #EAEAEB);
  background: var(--grey-white, #FFF);
  height: fit-content;
  position: relative;
  padding: 24px;
  margin-bottom: 16px;
}

.header {
  color: rgba(47, 49, 60, 1);
  margin: 0;
  font-family: 'Inter';
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}