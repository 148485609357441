$spacing: 1rem;
$block-spacing-vertical: calc(1rem * 3);
$block-spacing-horizontal: calc(1rem * 1.5);

.App {
  background-color: #FFFFFF;
  min-height: 100vh;
  height:100%;
  font-family: "Inter", sans-serif;
  color: #2F313C;
}