@import "../../themes/breakpoints";

.header {
  display: flex;
  justify-content: space-between;

  @include less-1000 {
    flex-direction: column;
    padding-inline: 8px;
  }
}

.options {
  display: flex;

  @include less-1000 {
    width: 100%;
    flex-direction: column;
    border: 1px solid #EAEAEB;
    border-radius: 8px;
  }
}

.optionsWrapper {
  display: flex;
  align-items: stretch;
  margin: 10px 0;
  max-height: 72px;

  background: #FFFFFF;
  border: 1px solid #EAEAEB;
  border-radius: 8px;

  @include less-1000 {
    justify-content: stretch;
    max-height: unset;
    margin: 16px 0 0 0;
    border: none;
  }
}

.toggle {
  margin-left: 16px;
  height: 48px;

  @include less-1000 {
    height: 40px;
  }

    & button {
      padding: 14px 16px !important;
      @include less-1000 {
        padding: 10px 16px !important;
      }
    }
    & :first-child {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }
    & :last-child {
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }
}

.btn {
  border: none !important;
  padding: 14px 24px !important;
  border-radius: 8px !important;

  @include less-1000 {
    padding: 10px 16px !important;
    min-width: 52px;
    height: 40px;

    & > span {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.toolboxButton {
  padding: 14px 24px !important;
  border-radius: 8px !important;

  @include less-1000 {
    padding: 10px 16px !important;
    min-width: 52px;
    height: 40px;

    & > span {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.cancel {
  border: none !important;
  padding: 14px 24px !important;
  border-radius: 8px !important;
  &>span {
    margin-left: 0;
    margin-right: 0;
  }

  @include less-1000 {
    padding: 10px 16px !important;
    min-width: 52px;
    height: 40px;
  }
}

.session {
  display: flex;
  align-items: center;
  padding: 0 20px;

  border-right: 1px solid #EAEAEB;

  @include less-1000 {
    border-bottom: 1px solid #EAEAEB;
    border-right: none;
    padding: 12px 18px;
    & :last-child {
      margin-left: auto;
    }
  }
}

.selectedSession {
  margin: 0 15px;
}

.measures {
  padding: 12px 16px;
  display: flex;
  align-items: center;
  @include less-1000 {
    overflow-x: scroll;
    scroll-behavior: smooth;
  }
}

.date {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #6D6F77;
  white-space: nowrap;
}

.name {
  font-family: 'Inter', 'Roboto', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  letter-spacing: 0.15px;
  color: #2F313C;
  line-height: 36px;

  @include less-1400 {
    font-size: 24px;
  }

  margin-top: 12px;
}


.back {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;

  display: flex;
  align-items: center;
  column-gap: 2rem;

  a {
    text-decoration: none;
    color: #4361EE;
  }
}

.invisible1000 {
  @include less-1000 {
    display: none;
  }
}

.collapsed {
  display: none;
}